import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const ModalAgregarCargo = ({ show, handleClose, guardarCargo, cargoEditando }) => {
    const [cargo, setCargo] = useState({
        TipoEmpleo: "",
        Empresa: "",
        FechaInicio: "",
        FechaFin: "",
        Ubicacion: "",
        Descripcion: "",
        Referencias: [], // Lista de referencias dentro del cargo
    });

    const [nuevaReferencia, setNuevaReferencia] = useState({
        Nombre: "",
        Cargo: "",
        Telefono: "",
        Mail: "",
    });

    // Si está editando, carga los datos del cargo seleccionado
    useEffect(() => {
        if (cargoEditando) {
            setCargo(cargoEditando);
        } else {
            setCargo({
                TipoEmpleo: "",
                Empresa: "",
                FechaInicio: "",
                FechaFin: "",
                Ubicacion: "",
                Descripcion: "",
                Referencias: [],
            });
        }
    }, [cargoEditando]);

    // Manejar cambios en los inputs del cargo
    const handleInputChange = (e) => {
        setCargo({ ...cargo, [e.target.name]: e.target.value });
    };

    // Manejar cambios en los inputs de la nueva referencia
    const handleReferenciaChange = (e) => {
        setNuevaReferencia({ ...nuevaReferencia, [e.target.name]: e.target.value });
    };

    // Agregar una nueva referencia a la lista
    const agregarReferencia = () => {
        if (!nuevaReferencia.Nombre || !nuevaReferencia.Cargo) {
            alert("El Nombre y el Cargo son obligatorios.");
            return;
        }

        setCargo({
            ...cargo,
            Referencias: [...cargo.Referencias, nuevaReferencia],
        });

        // Vaciar los campos después de agregar la referencia
        setNuevaReferencia({
            Nombre: "",
            Cargo: "",
            Telefono: "",
            Mail: "",
        });
    };

    // Eliminar referencia
    const eliminarReferencia = (index) => {
        setCargo({
            ...cargo,
            Referencias: cargo.Referencias.filter((_, i) => i !== index),
        });
    };

    // Guardar cargo con referencias en el componente padre
    const handleGuardar = () => {
        guardarCargo(cargo);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{cargoEditando ? "Editar Cargo" : "Agregar Cargo"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Tipo de Empleo</Form.Label>
                        <Form.Control type="text" name="TipoEmpleo" value={cargo.TipoEmpleo} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Empresa</Form.Label>
                        <Form.Control type="text" name="Empresa" value={cargo.Empresa} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha Inicio</Form.Label>
                        <Form.Control type="date" name="FechaInicio" value={cargo.FechaInicio} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha Fin</Form.Label>
                        <Form.Control type="date" name="FechaFin" value={cargo.FechaFin} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Ubicación</Form.Label>
                        <Form.Control type="text" name="Ubicacion" value={cargo.Ubicacion} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} name="Descripcion" value={cargo.Descripcion} onChange={handleInputChange} />
                    </Form.Group>

                    <hr />

                    <h6>Referencias</h6>
                    <div className="d-flex gap-2">
                        <Form.Group className="w-100">
                            <Form.Control type="text" placeholder="Nombre" name="Nombre" value={nuevaReferencia.Nombre} onChange={handleReferenciaChange} />
                        </Form.Group>
                        <Form.Group className="w-100">
                            <Form.Control type="text" placeholder="Cargo" name="Cargo" value={nuevaReferencia.Cargo} onChange={handleReferenciaChange} />
                        </Form.Group>
                        <Form.Group className="w-100">
                            <Form.Control type="text" placeholder="Teléfono" name="Telefono" value={nuevaReferencia.Telefono} onChange={handleReferenciaChange} />
                        </Form.Group>
                        <Form.Group className="w-100">
                            <Form.Control type="email" placeholder="Mail" name="Mail" value={nuevaReferencia.Mail} onChange={handleReferenciaChange} />
                        </Form.Group>
                        <Button variant="success" onClick={agregarReferencia}>+</Button>
                    </div>

                    {/* Mostrar referencias agregadas */}
                    <ul className="mt-3">
                        {cargo.Referencias.map((ref, index) => (
                            <li key={index} className="d-flex justify-content-between align-items-center border p-2 rounded">
                                {ref.Nombre} - {ref.Cargo}
                                <Button variant="danger" size="sm" onClick={() => eliminarReferencia(index)}>X</Button>
                            </li>
                        ))}
                    </ul>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                <Button variant="primary" onClick={handleGuardar}>{cargoEditando ? "Actualizar" : "Guardar"}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAgregarCargo;
