import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const ModalAgregarEducacion = ({ show, handleClose, guardarEducacion, educacionEditando }) => {
    const [educacion, setEducacion] = useState({
        InstitucionEducativa: "",
        Titulo: "",
        DisciplinaAcademica: "",
        FechaInicio: "",
        FechaFin: "",
        Descripcion: "",
        Aptitudes: [],
    });

    const [nuevaAptitud, setNuevaAptitud] = useState("");

    // Si está editando, llenamos los datos con la educación seleccionada
    useEffect(() => {
        if (educacionEditando) {
            setEducacion(educacionEditando);
        } else {
            setEducacion({
                InstitucionEducativa: "",
                Titulo: "",
                DisciplinaAcademica: "",
                FechaInicio: "",
                FechaFin: "",
                Descripcion: "",
                Aptitudes: [],
            });
        }
    }, [educacionEditando]);

    // Manejar cambios en los inputs de la educación
    const handleEducacionChange = (e) => {
        setEducacion({ ...educacion, [e.target.name]: e.target.value });
    };

    // Agregar aptitud a la educación actual
    const agregarAptitud = () => {
        if (!nuevaAptitud.trim()) return;
        setEducacion({ ...educacion, Aptitudes: [...educacion.Aptitudes, { Aptitud: nuevaAptitud }] });
        setNuevaAptitud(""); // Limpiar input de aptitud
    };

    // Eliminar aptitud de la educación actual
    const eliminarAptitud = (index) => {
        setEducacion({
            ...educacion,
            Aptitudes: educacion.Aptitudes.filter((_, i) => i !== index),
        });
    };

    // Enviar datos al componente padre
    const handleGuardar = () => {
        guardarEducacion(educacion);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{educacionEditando ? "Editar Educación" : "Agregar Educación"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Institución Educativa</Form.Label>
                        <Form.Control type="text" name="InstitucionEducativa" value={educacion.InstitucionEducativa} onChange={handleEducacionChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Título</Form.Label>
                        <Form.Control type="text" name="Titulo" value={educacion.Titulo} onChange={handleEducacionChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Disciplina Académica</Form.Label>
                        <Form.Control type="text" name="DisciplinaAcademica" value={educacion.DisciplinaAcademica} onChange={handleEducacionChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha Inicio</Form.Label>
                        <Form.Control type="date" name="FechaInicio" value={educacion.FechaInicio} onChange={handleEducacionChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha Fin</Form.Label>
                        <Form.Control type="date" name="FechaFin" value={educacion.FechaFin} onChange={handleEducacionChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} name="Descripcion" value={educacion.Descripcion} onChange={handleEducacionChange} />
                    </Form.Group>

                    {/* Sección de Aptitudes */}
                    <h6>Aptitudes</h6>
                    <div className="d-flex gap-2">
                        <Form.Control type="text" placeholder="Nueva Aptitud" value={nuevaAptitud} onChange={(e) => setNuevaAptitud(e.target.value)} />
                        <Button variant="success" onClick={agregarAptitud}>+</Button>
                    </div>
                    <ul className="mt-2">
                        {educacion.Aptitudes.map((apt, index) => (
                            <li key={index} className="d-flex justify-content-between">
                                {apt.Aptitud} <Button variant="danger" size="sm" onClick={() => eliminarAptitud(index)}>X</Button>
                            </li>
                        ))}
                    </ul>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
                <Button variant="primary" onClick={handleGuardar}>{educacionEditando ? "Actualizar" : "Guardar"}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAgregarEducacion;
