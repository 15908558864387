import React, { useContext, useState } from "react";
import "./ContenidoRegistro.css";
import { DarkModeContext } from "../../context/DarkModeContext";
import { Form, Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import ModalAgregarCargo from "./ModalAgregarCargo";
import ModalAgregarEducacion from "./ModalAgregarEducacion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faBriefcase,faEdit }from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Office } from "../../assets/svg/Iconos para interfaz de postulaciones-10.svg";
import { ReactComponent as Job } from "../../assets/svg/Iconos para interfaz de postulaciones-09.svg";

const ContenidoRegistro = () => {
    const { darkMode } = useContext(DarkModeContext);

    // Estado para almacenar el postulante
    const [postulante, setPostulante] = useState({
        Nombre: "",
        Correo: "",
        Telefono: "",
        FechaCreacion: new Date().toISOString(),
        Cv: null,
        Direccion: "",
        Contraseña: "",
        Dni: "",
        Estado: "activo",
        TipoUsuario: 9,
        Provincia: "",
        Localidad: "",
        CargosAnteriores: [],
        Educaciones: [],
        Aptitudes: [], // ✅ Nueva lista de aptitudes individuales
    });

    const [nuevaAptitud, setNuevaAptitud] = useState(""); // ✅ Estado para una aptitud nueva


    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Registrar");
    const [seccion, setSeccion] = useState("personal");

    // Modales de Cargo
    const [showModalCargo, setShowModalCargo] = useState(false);
    const [cargoEditando, setCargoEditando] = useState(null);

    // Modales de Educación
    const [showModalEducacion, setShowModalEducacion] = useState(false);
    const [educacionEditando, setEducacionEditando] = useState(null);

    // Manejo de cambios en los campos
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPostulante((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    // Manejar selección del archivo (CV) y convertirlo a Base64
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setPostulante({ ...postulante, Cv: reader.result.split(",")[1] });
            };
        }
    };

    // Agregar o Editar un Cargo
    const guardarCargo = (nuevoCargo) => {
        setPostulante({
            ...postulante,
            CargosAnteriores: cargoEditando
                ? postulante.CargosAnteriores.map((cargo) =>
                    cargo === cargoEditando ? nuevoCargo : cargo
                )
                : [...postulante.CargosAnteriores, nuevoCargo],
        });
console.log(nuevoCargo)
        setShowModalCargo(false);
        setCargoEditando(null);
    };

    // Agregar o Editar una Educación
    const guardarEducacion = (nuevaEducacion) => {
        setPostulante({
            ...postulante,
            Educaciones: educacionEditando
                ? postulante.Educaciones.map((edu) =>
                    edu === educacionEditando ? nuevaEducacion : edu
                )
                : [...postulante.Educaciones, nuevaEducacion],
        });

        setShowModalEducacion(false);
        setEducacionEditando(null);
    };

    // Manejo del envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch("api/postulantes/crearpostulante", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(postulante),
            });

            const result = await response.json();

            if (response.ok) {
                Swal.fire("Éxito", "Registro completado", "success");
            } else {
                Swal.fire("Error", result.message || "Error al registrar", "error");
            }
        } catch (error) {
            Swal.fire("Error", "No se pudo conectar con el servidor", "error");
        } finally {
            setIsLoading(false);
        }
    };
    const formatFechaMesAnio = (fechaStr) => {
        if (!fechaStr) return "";
        const fecha = new Date(fechaStr);
        return new Intl.DateTimeFormat("es-AR", {
          month: "long",
          year: "numeric",
        }).format(fecha);
      };
    return (
        <div className="container">
            <div className="pb-3 text-center" style={{ marginTop: "5em" }}>
                <h6 className="lato-bold fs-24">Perfil de candidato</h6>
            </div>

            <div className="bg-register s py-5">
                {/* Botones para cambiar entre secciones */}
                <div className="d-flex justify-content-center my-4">
                    <button
                        className={`btn-tab ${seccion === "personal" ? "active" : ""}`}
                        onClick={() => setSeccion("personal")}
                        type="button"
                    >
                        Información Personal
                    </button>
                    <button
                        className={`btn-tab ${seccion === "profesional" ? "active" : ""}`}
                        onClick={() => setSeccion("profesional")}
                        type="button"
                    >
                        Información Profesional
                    </button>
                </div>

                <Form onSubmit={handleSubmit}>
                    {/* Sección de Información Personal */}
                    {seccion === "personal" ? (
                        <>
                            <div className="row container">
                                <article className="d-flex justify-content-center my-3 col-6 flex-column">
                                    <div className="input-group">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input
                                            type="text"
                                            id="nombre"
                                            className="formdatos"
                                            name="Nombre"
                                            value={postulante.Nombre}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="password">Contraseña</label>
                                        <input
                                            type="password"
                                            id="password"
                                            className="formdatos"
                                            name="Contraseña"
                                            value={postulante.Contraseña}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            className="formdatos"
                                            name="Correo"
                                            value={postulante.Correo}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="genero">Género</label>
                                        <input
                                            type="text"
                                            id="genero"
                                            className="formdatos"
                                            name="Genero"
                                            value={postulante.Genero || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </article>

                                <article className="d-flex justify-content-center my-3 col-6 flex-column">
                                    <div className="input-group">
                                        <label htmlFor="dni">DNI</label>
                                        <input
                                            type="text"
                                            id="dni"
                                            className="formdatos"
                                            name="Dni"
                                            value={postulante.Dni}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="repeat-password">Repetir Contraseña</label>
                                        <input
                                            type="password"
                                            id="repeat-password"
                                            className="formdatos"
                                            name="RepeatPassword"
                                            value={postulante.RepeatPassword || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="telefono">Teléfono</label>
                                        <input
                                            type="text"
                                            id="telefono"
                                            className="formdatos"
                                            name="Telefono"
                                            value={postulante.Telefono}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="linkedin">LinkedIn</label>
                                        <input
                                            type="text"
                                            id="linkedin"
                                            className="formdatos"
                                            name="Linkedin"
                                            value={postulante.Linkedin || ""}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </article>
                            </div>

                            <div className="row d-flex justify-content-center container">
                                <div className="separadorRegistro d-flex">
                                    <p className="fs-14 text-light">Ubicación</p>
                                </div>
                            </div>

                            <div className="row container">
                                <article className="d-flex justify-content-center my-3 col-6 flex-column">
                                    <div className="input-group">
                                        <label htmlFor="provincia">Provincia</label>
                                        <input
                                            type="text"
                                            id="provincia"
                                            className="formdatos"
                                            name="Provincia"
                                            value={postulante.Provincia}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </article>
                                <article className="d-flex justify-content-center my-3 col-6 flex-column">
                                    <div className="input-group">
                                        <label htmlFor="localidad">Localidad</label>
                                        <input
                                            type="text"
                                            id="localidad"
                                            className="formdatos"
                                            name="Localidad"
                                            value={postulante.Localidad}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </article>
                            </div>

                            <div className="row container">
                                <article className="d-flex justify-content-center my-3 col-6 flex-column">
                                    <div className="input-group">
                                        <label htmlFor="direccion">Dirección</label>
                                        <input
                                            type="text"
                                            id="direccion"
                                            className="formdatos"
                                            name="Direccion"
                                            value={postulante.Direccion}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </article>
                            </div>
                        </>

                    ) : (
                            <>
                                <div className="row container d-flex justify-content-center">
                                    {/* Sección de Cargos Anteriores */}
                                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                                        <h5 className="mb-3 text-center">Cargos Anteriores</h5>
                                        
                                        <div className="mt-3">
                                            {postulante.CargosAnteriores.map((cargo, index) => (
                                                <div key={index} className="carta-trabajo border p-2 rounded">
                                                  <h6 className="fs-24 text-center" >Experiencia Laboral</h6>
                                                  <article className="row">
                                                    <article className="col-6 d-flex justify-content-center align-items-center">
                                                      <div>
                                                        
                                                        <Job className="icon-register" />
                                                        
                                                      </div>
                                                    </article>
                                                    <article className="col-6 d-flex justify-content-center align-items-center">
                                                      <div>
                                                        
                                                      <strong>{cargo.TipoEmpleo}</strong>
                                                        
                                                      </div>

                                                    </article>
                                                   


                                                  </article>
                                                  <article className="row">
                                                  <article className="col-6 d-flex flex-column justify-content-center align-items-center">
                                                      <div>
                                                        
                                                      <p className="fs-16 lato-bold text-center"> {cargo.Empresa}</p>
                                                      <p className="fs-14">
  {formatFechaMesAnio(cargo.FechaInicio)} -{" "}
  {cargo.FechaFin === "" ? "Actualmente" : formatFechaMesAnio(cargo.FechaFin)}
</p>
                                                        
                                                      </div>
                                                      
                                                    </article>
                                                    <article className="col-6 d-flex justify-content-center">
                                                      <div>
                                                        
                                                      <Button size="sm" className="btn-publicar border-0 me-2"
                                                        onClick={() => { setCargoEditando(cargo); setShowModalCargo(true); }}
                                                        style={{
                                                          padding: "0 6px",
                                                          fontSize: "12px",
                                                          borderRadius:"32px"
                                                        }}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                        
                                                      </div>
                                                      
                                                    </article>
                                                  </article>
                                                     
                                                    
                                                </div>
                                            ))}
                                            <Button className="btn-publicar buttonAgregar border-0" onClick={() => { setShowModalCargo(true); setCargoEditando(null); }} style={{borderRadius:"32px", marginTop:"1em"}}>
                                            Añadir Experiencia +
                                        </Button>
                                        </div>
                                    </div>

                                    {/* Sección de Educación */}
                                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                                        <h5 className="fs-24 text-center">Educación</h5>
                                        
                                        <div className="mt-3">
                                          
                                            {postulante.Educaciones.map((edu, index) => (
                                               <div key={index} className="carta-trabajo border p-2 rounded">
                                               <h6 className="fs-24 text-center" >Experiencia Laboral</h6>
                                               <article className="row">
                                                 <article className="col-3 d-flex justify-content-center align-items-center">
                                                   <div>
                                                     
                                                     <Office className="icon-register" />
                                                     
                                                   </div>
                                                 </article>
                                                 <article className="col-3 d-flex justify-content-center align-items-center">
                                                   <div>
                                                     
                                                   <strong>{edu.Titulo}</strong>
                                                     
                                                   </div>

                                                 </article>
                                                 <article className="col-4 d-flex flex-column justify-content-center align-items-center">
                                                   <div>
                                                     
                                                   <p className="fs-16"> {edu.InstitucionEducativa}</p>
                                                   <p className="fs-14"> {edu.FechaInicio} - {edu.FechaFin}</p>
                                                     
                                                   </div>
                                                   
                                                 </article>
                                                 <article className="col-2 d-flex justify-content-center align-items-center">
                                                   <div>
                                                     
                                                   <Button  size="sm" className="btn-publicar border-0 me-2"
                                                     onClick={() => { setEducacionEditando(edu); setShowModalEducacion(true); }}
                                                     style={{
                                                       padding: "0 6px",
                                                       fontSize: "12px",
                                                       borderRadius:"32px"
                                                     }}>
                                                     <FontAwesomeIcon icon={faEdit} />
                                                 </Button>
                                                     
                                                   </div>
                                                   
                                                 </article>


                                               </article>
                                                  
                                                 
                                             </div>
                                                // <div key={index} className="border p-2 rounded">
                                                //     <strong>{edu.Titulo}</strong> - {edu.InstitucionEducativa}
                                                //     <Button variant="warning" size="sm" className="ms-2"
                                                //         onClick={() => { setEducacionEditando(edu); setShowModalEducacion(true); }}>
                                                //         Editar
                                                //     </Button>
                                                // </div>
                                            ))}
                                            <Button className="btn-publicar buttonAgregar border-0" onClick={() => { setShowModalEducacion(true); setEducacionEditando(null); }} style={{borderRadius:"32px", marginTop:"1em"}}>
                                            Agregar Educación +
                                        </Button>
                                        </div>
                                    </div>
                                </div>

                                {/* Modal de Cargos */}
                                <ModalAgregarCargo
                                    show={showModalCargo}
                                    handleClose={() => setShowModalCargo(false)}
                                    guardarCargo={guardarCargo}
                                    cargoEditando={cargoEditando}
                                />

                                {/* Modal de Educación */}
                                <ModalAgregarEducacion
                                    show={showModalEducacion}
                                    handleClose={() => setShowModalEducacion(false)}
                                    guardarEducacion={guardarEducacion}
                                    educacionEditando={educacionEditando}
                                />{/* Sección de Aptitudes */}
                                <div className="container mt-4">
                                    <h5 className="mb-3">Aptitudes</h5>

                                    {/* Input y Botón para Agregar Aptitud */}
                                    <div className="d-flex gap-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Agregar nueva aptitud"
                                            value={nuevaAptitud}
                                            onChange={(e) => setNuevaAptitud(e.target.value)}
                                        />
                                        <Button
                                            variant="success"
                                            onClick={() => {
                                                if (nuevaAptitud.trim()) {
                                                    setPostulante({
                                                        ...postulante,
                                                        Aptitudes: [...postulante.Aptitudes, { Aptitud: nuevaAptitud }],
                                                    });
                                                    setNuevaAptitud(""); // ✅ Limpiar el input
                                                }
                                            }}
                                        >
                                            +
                                        </Button>
                                    </div>

                                    {/* Lista de Aptitudes Agregadas */}
                                    <div className="mt-3">
                                        {postulante.Aptitudes.map((apt, index) => (
                                            <div key={index} className="border p-2 rounded d-flex justify-content-between">
                                                <span>{apt.Aptitud}</span>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setPostulante({
                                                            ...postulante,
                                                            Aptitudes: postulante.Aptitudes.filter((_, i) => i !== index),
                                                        });
                                                    }}
                                                >
                                                    X
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>

                    )}

                    {/* Botón de envío */}
                    <div className="d-flex justify-content-center">
                        <Button
                            disabled={isLoading}
                            className={isLoading ? "my-4 btn-login-disabled lato-bold border-0" : "my-4 btn-login lato-bold border-0"}
                            type="submit"
                        >
                            {isLoading ? (
                                <>
                                    Loading...
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                </>
                            ) : (
                                buttonText
                            )}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default ContenidoRegistro;
