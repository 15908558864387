import React from 'react'
import FormularioCuestionario from '../../components/postulantes/FormularioCuestionario'

const Formulario = () => {
  return (
    <div>
        <div>
            <FormularioCuestionario/>

        </div>

    </div>
  )
}

export default Formulario